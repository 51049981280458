.modal {
    width: 100vw;
    height: 100vh;
    background-color:rgba(0,0,0,0.4) ;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 1000;

}

.modal.active {
    opacity: 1;
    pointer-events: all;
    z-index: 1000;

}
.modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
    width: 600px;
    height: auto;
    z-index: 1000;
    margin-left: 700px;
}

.modal__content.active {
    transform: scale(1);
    z-index: 1000;
}
