.mainBouquetContainer {
    /*background: rgb(0, 150, 208);*/
    display: flex;
    height: 100%;
    margin: 20px;
    flex-wrap: nowrap; align-content: flex-start;
}
.itemMainBouquet-1, .itemMainBouquet-2 {
    /*background: rgb(241, 101, 41);*/
    /*border: 1px solid;*/
    width: 100%;
    margin: 10px;
}

.itemMainBouquet-1 {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 0;

}

.textBouquet {
    margin: 10px 10px 10px 0;
    width: 250px;
}

.containerForm{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.quantity {
    width: 80px;
    margin: 0 2px 0 2px;
}
.selectBouquet {
    height: 30px;
    width: 250px;
}

.textBouquetDescription {
    margin: 10px 10px 10px 0;
    width: 250px;
    height: 130px;
}

.itemMainBouquet-2 {
    display: flex; height: 100%; flex-wrap: wrap; align-content: flex-start; justify-content: flex-end;
}
.quantityItem2 {
    width: 80px;
    margin: 10px 10px 0 0px;
    height: 30px;
}

.containerBtm {
    display: flex; height: 300px; flex-direction: column; flex-wrap: wrap-reverse;
}


.bouquetBtm {
    margin: 0 0 10px 0;
}

.indexBtm {
    margin: 70% 0 0 0;
}

.textMainCard {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 9pt;
}

.textMainCardTitle {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 11pt;
    background: rgba(37,219,237,0.8);
    width: 230px;
}
