nav, .sidebar {
    background-color: #f3f2f2;
    flex-grow: 0;
    flex-shrink: 0;
}

nav {
    text-align: left;
    padding: 20px;
    order: 1;
    height: auto;
    margin: 0 0 10px 0;
}
