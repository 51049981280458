
body {
    margin: 0;
    font-size:16px;
}
.container {
    display: flex;
    min-height: 100%;

}

.mainCard {
    width: 700px;
    text-align: left;
    background: #f3f2f2;
    opacity: 1.5;
    margin: 20px 0 0 0;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 1),
    -23px 0 20px -23px rgba(0, 0, 0, .8),
    23px 0 20px -23px rgba(0, 0, 0, .8),
    0 0 40px rgba(0, 0, 0, .1) inset;
}

div.container {
    padding: 0;
    margin: 0;
}

.main {
    flex-grow: 1;
    order: 2;
}
.inputCount {
    width: 150px;
    position: fixed;
}
.inputCount1 {
    width: 70px;
}
.inputCount2 {
    width: 100px;
}

button{
    margin: 2px;
}

nav, .sidebar {
    background-color: #a4c0f3;
    flex-grow: 0;
    flex-shrink: 0;
}

nav {
    text-align:left;
    padding: 20px;
    order: 1;
    height: 100vh;
}

.sidebar, .main {
    text-align:left;
    padding: 20px;
}
header {
    text-align:left;
    width: 100%;
    background-color: #638fe0;
    height: 2%;
    padding: 20px 0 20px 20px;
    position: fixed;
    z-index: 1000;
}


header > a {
    color: black;
    text-decoration: none;
    padding: 0 1rem;

}

header > a:hover {
    color: white;
}

footer {
    text-align:center;
    background-color: #638fe0;
    height: 5px;
    padding: 20px;
    color: white;
    position: fixed;
    left: 0; bottom: 0;
    width: 100%;
}


.mainStore {
    justify-content: center;
    width: 100px;
    height: 100px;
    background: #aaaaaa;
    border-radius: 10px;

}
:root {
    --transitionTimingFunction: ease;
    --transitionDuration: 0.2s;
}
.plus {
    position: relative;
    --size: 50px;
    --heightLine: 4px;
    --colorLine: black;
    --scale: 0.8;
    --backgroundColor: #a4c0f3;
    --radius: 0.2;
    --hoverColorLine: white;
    --hoverBackgroundColor:  #638fe0;
    border-radius: calc( 50% * var(--radius) );
    background-color: var(--backgroundColor);
    width: 90px;
    height: 90px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
            user-select: none;
    margin: 0 0 5px 0;

    transition-duration: 0.2s;

    transition-duration: var(--transitionDuration);
    transition-timing-function: ease;
    transition-timing-function: var(--transitionTimingFunction);
    transition-property: background-color;
}

.plus:hover {
    background-color: var(--hoverBackgroundColor);
}

.btnСard {
    display: inline-flex;
    width: 640px;
    justify-content: space-between;
    height: auto;

}

.cardCount {
    width: 400px;
}
.imgCard {
    width: 200px;
    height: 200px;
    float: right;
    margin: 20px 30px 0 0;
    z-index: 999;

}
.imgCardModal {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 50px;
    left: 350px;

}
.autoComplete {
    position: absolute;
    width: 300px;
    background: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.15);
    z-index: 11111;
    max-height: 240px;
    height: auto;
    overflow: auto;
}

.autoComplete__item {
    padding: 5px;
    text-align:left;
}
.autoComplete__item:hover {
    background: #ded8d8;
    cursor: pointer;
    transition: cubic-bezier(1,0,0,1) .3s all;
}
.autoComplete__item a {


}
.tableItem {
    /*border: 1px solid black;*/
    margin: 5px 0 0 0;
    background: #eeeded;
}
.tableItem1 {
    width: 30px;
    background: #eeeded;
}
.tableItem2 {
    width: 300px;
    background: #eeeded;
}
.tableItem3 {
    width: 100px;
    background: #eeeded;
}
.tableItem4 {
    width: 200px;
    background: #eeeded;
}

.cardName {
    width: 200px;
}

.inputCard{
    width: 200px;
    margin-right: 10px;
    position: fixed;
    margin-left: 400px;
}



.inputCard1 {
    width: 200px;
    margin: 10px 0 0 0;
    border-radius: 5px;

}
.titleNewProduct {
    margin: 10px 0 0 0;
}
.titleNewProductModal {
    font-size: 20px;
}
.btmDelete {
    margin: 2px 2px 2px 70%;
    cursor: pointer;
}
.btmEdit {
    cursor: pointer;
}

.loader-container {
    padding: 0;
    margin: -20px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    vertical-align:middle;
    z-index: 1000;
    position: fixed;
}
.loader-container.show {
    display:table-cell;
}
.loader-container.hide {
    display: none;
}
.loader {
    position: absolute;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    left:45%;
    top:30%;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin: 50px auto;
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.inputCard2 {
    width: 150px;
}

/*#left*/
/*flex-grow: 1*/
/*#right*/
/*width: 300px*/
.btmMain {
    cursor: pointer;
}
.btmMainCreate {
    cursor: pointer;
}
.plus::before, .plus::after {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: var(--colorLine);
    width: calc( var(--scale) * 100% );
    height: var(--heightLine);
    transition-duration: 0.2s;
    transition-duration: var(--transitionDuration);
    transition-timing-function: ease;
    transition-timing-function: var(--transitionTimingFunction);
    transition-property: background-color, -webkit-transform;
    transition-property: transform, background-color;
    transition-property: transform, background-color, -webkit-transform;
}

.plus::after {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
            transform: translate(-50%, -50%) rotate(90deg);
}

.plus:hover::before, .plus:hover::after {
    background-color: var(--hoverColorLine);
}

.plus:hover::before {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
}

.plus:hover::after {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
}

.pagMain {
    background: white;
}

.mainBouquetContainer {
    /*background: rgb(0, 150, 208);*/
    display: flex;
    height: 100%;
    margin: 20px;
    flex-wrap: nowrap; align-content: flex-start;
}
.itemMainBouquet-1, .itemMainBouquet-2 {
    /*background: rgb(241, 101, 41);*/
    /*border: 1px solid;*/
    width: 100%;
    margin: 10px;
}

.itemMainBouquet-1 {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 0;

}

.textBouquet {
    margin: 10px 10px 10px 0;
    width: 250px;
}

.containerForm{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.quantity {
    width: 80px;
    margin: 0 2px 0 2px;
}
.selectBouquet {
    height: 30px;
    width: 250px;
}

.textBouquetDescription {
    margin: 10px 10px 10px 0;
    width: 250px;
    height: 130px;
}

.itemMainBouquet-2 {
    display: flex; height: 100%; flex-wrap: wrap; align-content: flex-start; justify-content: flex-end;
}
.quantityItem2 {
    width: 80px;
    margin: 10px 10px 0 0px;
    height: 30px;
}

.containerBtm {
    display: flex; height: 300px; flex-direction: column; flex-wrap: wrap-reverse;
}


.bouquetBtm {
    margin: 0 0 10px 0;
}

.indexBtm {
    margin: 70% 0 0 0;
}

.textMainCard {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 9pt;
}

.textMainCardTitle {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 11pt;
    background: rgba(37,219,237,0.8);
    width: 230px;
}

nav, .sidebar {
    background-color: #f3f2f2;
    flex-grow: 0;
    flex-shrink: 0;
}

nav {
    text-align: left;
    padding: 20px;
    order: 1;
    height: auto;
    margin: 0 0 10px 0;
}

.modal {
    width: 100vw;
    height: 100vh;
    background-color:rgba(0,0,0,0.4) ;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 1000;

}

.modal.active {
    opacity: 1;
    pointer-events: all;
    z-index: 1000;

}
.modal__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    transition: 0.4s all;
    width: 600px;
    height: auto;
    z-index: 1000;
    margin-left: 700px;
}

.modal__content.active {
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: 1000;
}

